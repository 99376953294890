import React from "react";
import { Link } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { IoMail } from "react-icons/io5";
import { IoIosPhonePortrait } from "react-icons/io";
import imgold from "./Images/imgold.png";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaGooglePlusG } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";

function Footer() {
  return (
    <footer
      className="page-footer font-small mdb-color pt-4"
      style={{ background: "#22262f", color: "white" }}
    >
      <div className="container text-center text-md-left">
        <div className="row text-center text-md-left mt-3 pb-3">
          <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-2">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              <img
                id="animated-example"
                className="mx-lg-2"
                style={{ width: "80%", margin: "5px", marginTop: "-70px" }}
                src={imgold}
                alt="Logo"
              />
            </h6>
            <table style={{ marginTop: "-25px" }}>
              <tbody>
                <tr>
                  <td>Bank Details:</td>
                  <td style={{ paddingLeft: "10px", fontSize: "12px" }}>
                    UNION BANK OF INDIA
                  </td>
                </tr>
                <tr>
                  <td>Branch:</td>
                  <td style={{ paddingLeft: "10px", fontSize: "12px" }}>
                    BAGALKOT
                  </td>
                </tr>
                <tr>
                  <td>A/C. No.:</td>
                  <td style={{ paddingLeft: "10px", fontSize: "12px" }}>
                    031721010000010
                  </td>
                </tr>
                <tr>
                  <td>IFSC Code:</td>
                  <td style={{ paddingLeft: "10px", fontSize: "12px" }}>
                    UBIN0903175
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              Useful links
            </h6>
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <a href="https://wppv2-1.relysoft.in/products.php?id=mkBMViM2RA==">
                Products
              </a>
            </p>
            <p>
              <Link to="/about-us">About Us</Link>
            </p>
            <p>
              <Link to="/vision">Our Vision</Link>
            </p>
            <p>
              <Link to="/values">Our Values</Link>
            </p>
            <p>
              <Link to="/contact-us">Contact Us</Link>
            </p>
          </div>

          <hr className="w-100 clearfix d-md-none" />

          <div className="col-md-5 col-lg-4 col-xl-4 mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">Contact Us</h6>
            <p>
              <FaHome /> WARD NO 10/1 ,MELLIGERI JIN BEHIND MELLIGERI PETROL
              BUNK KALADAGI ROAD BAGALKOT-587101,KARNATAKA
            </p>
            <p>
              <IoMail />
              kadlimattipharma@gmail.com
            </p>
            <p>
              <IoIosPhonePortrait />
              CUSTOMER CARE: +91-7349156799, +91-9535127281
            </p>
          </div>
        </div>
      </div>
      <hr />
      <div className="container text-center text-md-left">
        <div className="row d-flex ">
          <div>
            <p className="text-center text-md-left">
              Powered By:
              <a target="_BLANK" href="http://www.relysoft.in/">
                <strong> relysoft.in</strong>
              </a>
            </p>
          </div>
        </div>
        {/* <hr /> */}
        <div className="col-md-5 col-lg-4 ml-lg-0">
          <div className="text-center text-md-right">
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <a className="btn-floating btn-sm rgba-white-slight mx-1">
                  <FaFacebookF />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="btn-floating btn-sm rgba-white-slight mx-1">
                  <FaTwitter />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="btn-floating btn-sm rgba-white-slight mx-1">
                  <FaGooglePlusG />
                </a>
              </li>
              <li className="list-inline-item">
                <a className="btn-floating btn-sm rgba-white-slight mx-1">
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
