
import './App.css'

import Product  from './Product';
import Pfooter from './Pfooter';

import Header from './Header';


function ProductList(){
    return(
        <>
        <Header/>
        
        <Product/> 

        <Pfooter/>
       
        </>
    )
}
export default ProductList;