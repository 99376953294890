import img2 from './img2.png'
import R_Logo from './R_Logo.png'
import { Link, useHistory } from 'react-router-dom';
// import './App.css'


export default function SalesManHeader() {


    //const history = useHistory();
    function logout() {
        window.localStorage.removeItem('user-info')
        //history.push('/Customer');
        window.location.href = "/Customer";
    }
    return (
      <>
        <div className="product_header">
          <Link to="/">
            <img
              src={img2}
              height={100}
              width={100}
              id="S"
              alt=""
              style={{ marginTop: "10px" }}
            />
          </Link>
          <h4
            style={{ marginTop: "40px", color: " #882205", fontSize: "27px" }}
          >
            KADLIMATTI PHARMACEUTICALS
          </h4>
          <img src={R_Logo} height={100} width={100} className="R" alt="" />
        </div>
      </>
    );
}