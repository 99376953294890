import React, { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import img2 from './img2.png'
import R_Logo from './R_Logo.png'
import './Salesman.css';
import axios from "axios";
import Product from "../../Product";
import SmOrder from "./SmOrder";
import { useDispatch } from 'react-redux';
import { add, addcstName, addcstPlace } from "../../store/CustOrder";
import GlobalVariable from "../../GlobalVariable";


export const UserContext = React.createContext();




export default function SalesmanDashboard() {
    //  const [deletes, setDeletes] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [showList, setShowList] = useState(false)
    const [showData, setshowData] = useState(false)
    const [data, setData] = useState(1);
    const [cstName, setCstName] = useState('');
    const [cstPlace, setCstPlace] = useState('')
    // const [hideData, setHideData] = useState([]);
    const [value, setValue] = useState("");
    const [noOfRows, setNoOfRows] = useState(1);
    const [allorderData, setAllOrderData] = useState([]);
    const [cstrNme, setCstrNme] = useState('');
    const [cstrPlce, setCstrPlce] = useState('');
    const [userName, setUserName] = useState('');
    const [custmerCode, setCustmerCode] = useState('');

    const dispatch = useDispatch();

    const user = JSON.parse(localStorage.getItem('user-info'));

    function searchCustomerName(e) {
        //  setLoading(true);
        console.log(user);
        axios.post("https://popsv3.relysoft.in/api/Search/CustomerByName/", {
            wClientid: GlobalVariable.wcClient,
            search: e.target.value,
            areaCode: user.areaCode + "" + 0
        }, {
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + user.token,
            }
        }).then(((result) => {
            console.log(result);
            setCustomer(result.data);
            setUserName(result)


        }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);

                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }
            })
            .finally(() => {
                // setLoading(false);

            });

        setValue(e.target.value);
        //console.log(data.name)
        //console.log(user.name);

    }



    // const handleDelete = (index) => {
    //     setDeletes(deletes.filter((i) => i !== index));
    // }




    function addValuehandler() {
        // console.log(cstName, cstPlace)
        setShowList(false)
        setshowData(true)
        setAllOrderData([...allorderData, {

            cstrNme: cstName,
            cstrPlce: cstPlace,


        }])

    }






    // function deletehandler(record) {
    //     console.log({ record, allData });
    //     setAllData(
    //         allData.filter((product) => product.slno !== record.slno)
    //     );
    // };


    function deletehandlerr(i) {
        const newData = allorderData.filter((l) => l.id !== i)
        setAllOrderData(newData)
    }






    // useEffect(() => {
    //     const CustomerOrder = window.localStorage.getItem("CustomerOrder");
    //     // console.log(CustomerOrder)
    //     const saveData = JSON.parse(CustomerOrder);
    //     setData(saveData.data);
    //     setCstName(saveData.cstName);
    //     setCstPlace(saveData.cstPlace);
    //     // setAllData(saveData.allData);
    //     // setCstrNme(saveData.cstrNme);
    //     // setCstrPlce(saveData.cstrPlce);


    // }, []);

    // useEffect(() => {
    //     const saveToAllData = { data, cstName, cstPlace }
    //     console.log(saveToAllData);
    //     window.localStorage.setItem("CustomerOrder", JSON.stringify(saveToAllData));
    // });


    // useEffect(() => {
    //     const CustomerOrder = window.localStorage.getItem("CustomerOrder");
    //     const saveData = JSON.parse(CustomerOrder);
    //     setAllOrderData(saveData.allorderData);
    //     setCstrNme(saveData.cstrNme);
    //     setCstrPlce(saveData.cstrPlce);



    // }, []);

    // useEffect(() => {
    //     const saveToAllData = { allorderData, cstrNme, cstrPlce }
    //     console.log(saveToAllData);
    //     window.localStorage.setItem("CustomerOrder", JSON.stringify(saveToAllData));
    // });








    function logout() {
        window.localStorage.removeItem('user-info')
        //history.push('/Customer');
        window.location.href = "/SalesMan";
    }


    return (
      <>
        <div className="customer_header">
          <Link to="/">
            <img
              src={img2}
              height={78}
              // width={30}
              id="S"
              alt=""
              style={{ marginTop: "-10px" }}
            />
          </Link>
          <h4
            style={{ marginTop: "40px", color: " #882205", fontSize: "27px" }}
          >
            KADLIMATTI PHARMACEUTICALS
          </h4>
          <img
            src={R_Logo}
            height={50}
            width={50}
            className="R"
            alt=""
            style={{ marginTop: "0px" }}
          />
        </div>

        <div className="UserDiv">
          <p>Hello!{user.name}</p>
          <button className="reportBTN">Reports</button>
          <button onClick={logout} className="logoutBTN">
            Log Out
          </button>
        </div>

        <div className="searchBar">
          <input
            value={value}
            type="search"
            style={{ width: "1100px" }}
            onChange={searchCustomerName}
            onKeyPress={() => setShowList(true)}
            className="searchInp"
            placeholder="Search Customer"
          />
        </div>

        {showList ? (
          <div className="customerlists">
            {customer.map((elm, i) => {
              return (
                <div
                  key={i}
                  style={{ cursor: "pointer" }}
                  className="customerlist"
                  onMouseOver={() => setValue(elm.custName)}
                  onClick={
                    () =>
                      // addValuehandler
                      //   addedDatas +
                      // setAllOrderData(elm.custName + elm.place) +
                      {
                        const val = elm.custCode;
                        const cName = elm.custName;
                        const places = elm.place;
                        console.log({ val }, { cName }, { places });
                        dispatch(add(val));
                        dispatch(addcstName(cName));
                        dispatch(addcstPlace(places));
                        // setCustmerCode(elm.custCode) +
                        setCstName(elm.custName);
                        setCstPlace(elm.place);
                        setShowList(false);
                        setshowData(true);
                      }
                    // setHideData(elm.custName + elm.place)
                    //+ setAllData(elm.custName + elm.place)
                    //console.log(elm.custName, elm.place)
                  }
                >
                  <li>
                    <span style={{ fontWeight: "600", fontSize: "20px" }}>
                      Name:
                    </span>{" "}
                    {elm.custName}
                  </li>
                  <li>
                    <span style={{ fontWeight: "600", fontSize: "20px" }}>
                      Place:
                    </span>{" "}
                    {elm.place}
                  </li>
                </div>
              );
            })}
            {/* cdata={elm.custName} */}
          </div>
        ) : null}

        {showData
          ? [...Array(data)].map((index, i) => {
              console.log({ index });
              return (
                <div className="detailsCst" key={i}>
                  <p>
                    <span style={{ fontWeight: "600" }} cdata={cstName}>
                      Customer Name :{" "}
                    </span>
                    {cstName}
                  </p>
                  <p>
                    <span style={{ fontWeight: "600" }}>Place : </span>{" "}
                    {cstPlace}
                  </p>
                  <button className="DltBtn" onClick={() => setData(data - 1)}>
                    X
                  </button>
                  <Link to="/components/salesman/SmOrder">
                    <button className="OrdrBtn">Order</button>{" "}
                  </Link>
                  <Link to="/components/salesman/OrderByComp">
                    {" "}
                    <button className="CompBtn">Order by comp</button>
                  </Link>
                  <button className="BillBtn">Pending Bills</button>
                  <p name={"sushant"}></p>
                </div>
              );
            })
          : null}
        <div>
          <UserContext.Provider value={cstName} />
        </div>

        {/* {
                allorderData.map((Product, i) => {

                    return (
                        <ul key={i} >

                            <li>name:{Product.cstrNme}</li>
                            <li>Place:{Product.cstrPlce}</li>
                            <span onClick={() => deletehandlerr(Product.i)} >Delete</span>
                        </ul>
                    )
                })
            } */}
      </>
    );
}