import React from "react";
import img2 from "../../Images/img2.png";
import Footer from "../../Footer";
import { useHistory } from "react-router-dom";
import "../../App.css";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import AboutUs from "../AboutUs/AboutUs";
import ContactUs from "../ContactUs/ContactUs";
import OurVision from "../OurVision/OurVision";
import OurValues from "../OurValues/OurValues";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import HomeComponent from "./components/HomeComponent";
import "./css/home.css";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

function Home(props) {
  const pageName =
    props && props.match && props.match.params && props.match.params.pageName;
  // console.log(pageName);
  const history = useHistory();

  const [currentComponent, setCurrentComponent] = useState(pageName);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.substr(1);
      setCurrentComponent(hash || "Home");
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const renderComponent = useCallback(() => {
    // console.log({ currentComponent });
    switch (currentComponent) {
      case "AboutUs":
        return <AboutUs />;
      case "OurVision":
        return <OurVision />;
      case "OurValues":
        return <OurValues />;
      case "ContactUs":
        return <ContactUs />;
      default:
        return <HomeComponent />;
    }
  }, [currentComponent]);

  const handleNavLinkClick = (component) => {
    history.push("#" + component);
    setCurrentComponent(component);
  };

  return (
    <>
      <div
        className="row"
        style={{
          background:
            "linear-gradient(to bottom right, rgb(255 255 255), rgb(234 233 233)), url(4.jpg) center center no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="col-sm-2">
          <img
            src={img2}
            className="mx-lg-3"
            alt=""
            style={{ width: "50%" }}
          />
        </div>
        <div className="col-sm-4">
          <h4
            style={{ marginTop: "40px", color: " #882205", fontSize: "27px" }}
          >
            {" "}
            KADLIMATTI PHARMACEUTICALS
          </h4>
        </div>
        <div className="col-sm-6" style={{ color: "#f7f6f6" }}>
          <div
            className="row onlyphone"
            style={{ marginTop: "40px", color: "white" }}
          >
            <div
              className="col-sm-6"
              style={{ color: "#882205", fontWeight: 600, fontSize: "18px" }}
            >
              <PhoneOutlined
                style={{ paddingRight: "5px", fontSize: "27px" }}
              />
              +91-7349156799, +91-9535127281
            </div>
            <div
              className="col-sm-6"
              style={{ color: "#882205", fontWeight: 600, fontSize: "18px" }}
            >
              <MailOutlined style={{ paddingRight: "5px", fontSize: "27px" }} />
              kadlimattipharma@gmail.com
            </div>
          </div>
        </div>
      </div>
      <Navbar expand="lg" className="  ">
        <Container fluid>
          {/* <Navbar.Brand href="#">Navbar scroll</Navbar.Brand> */}
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{
                maxHeight: "100px",
                display: window.innerWidth < 1024 ? "flex" : "contents",
                flexDirection: "column",
                flexWrap: "nowrap",
              }}
              navbarScroll
            >
              <Nav.Link
                className={
                  (currentComponent === "Home" || currentComponent === "home"
                    ? "activeLink"
                    : "") + "  linksPadding "
                }
                onClick={() => handleNavLinkClick("Home")}
              >
                Home
              </Nav.Link>
              <Nav.Link
                className={
                  (currentComponent === "AboutUs" ? "activeLink" : "") +
                  " mr-3  linksPadding "
                }
                onClick={() => handleNavLinkClick("AboutUs")}
              >
                About Us
              </Nav.Link>
              <Nav.Link
                className={
                  (currentComponent === "OurVision" ? "activeLink" : "") +
                  " mr-3 linksPadding "
                }
                onClick={() => handleNavLinkClick("OurVision")}
              >
                Our Vision
              </Nav.Link>
              <Nav.Link
                className={
                  (currentComponent === "OurValues" ? "activeLink" : "") +
                  " mr-3 linksPadding "
                }
                onClick={() => handleNavLinkClick("OurValues")}
              >
                Our Values
              </Nav.Link>
              <Nav.Link
                className={
                  (currentComponent === "ContactUs" ? "activeLink" : "") +
                  " mr-3 linksPadding "
                }
                onClick={() => handleNavLinkClick("ContactUs")}
              >
                Contact Us
              </Nav.Link>

              <Nav.Link
                className="linksPadding"
                onClick={() => history.push("/ProductList")}
              >
                Product List
              </Nav.Link>
            </Nav>
            <Nav
              // className="d-flex"
              style={{
                maxHeight: "100px",
                position: window.innerWidth > 768 ? "absolute" : false,
                right: 0,
              }}
              navbarScroll
            >
              <NavDropdown
                title="Login As"
                style={{ marginRight: "20px" }}
                align={"end"}
                id="navbarScrollingDropdown"
              >
                <NavDropdown.Item onClick={() => history.push("/Customer")}>
                  Customer
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => history.push("/Company")}>
                  Company
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div
        className="middle"
        style={{
          minHeight: window.innerHeight / 1.4,
        }}
      >
        {renderComponent()}
      </div>
      <Footer />
    </>
  );
}
export default Home;
