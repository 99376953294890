import React from 'react';
import { Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import ProductList from '../ProductList';
import New_Product from '../New_Product';
import All_P from '../All_P';
import CustomerDashboard from '../components/customer/CustomerDashboard';
import SalesMan from '../SalesMan';
import Demo from '../pages/Demo/Demo';
import OrderByComp_Customer from '../components/customer/OrderByComp_Customer';
import SalesmanDashboard from '../components/salesman/SalesmanDashboard';
import CompanyDashboard from '../components/company/CompanyDashboard';
import SmOrder from '../components/salesman/SmOrder';
import OrderByComp from '../components/salesman/OrderByComp';
import Ledger from '../components/customer/Ledger';
import PendingBills from '../components/customer/PendingBills';
import BounceList from '../components/customer/BounceList';
import BillTracking from '../components/customer/BillTracking';
import OrderList from '../components/customer/OrderList';
import DownloadInvoice from '../components/customer/DownLoadInvoice';
import Company from '../LoginPages/Company';
import Customer from '../LoginPages/Customer';
import OrderUpload from '../pages/DocUpload/OrderUpload';
import { useSelector } from 'react-redux';
import { Button, Result } from 'antd';
// const OrderUpload = lazy(() => import('../pages/DocUpload/OrderUpload'));

const RoutesComponents = () => {
  const data = useSelector((state) => state.userSlice);
  console.log({ UserData: window.location.pathname });
  const loggedIn = data.login;

  return (
    <>
      <Route exact path='/' component={Home} />
      <Route exact path='/Customer' component={loggedIn ? CustomerDashboard : Customer} />
      <Route exact path='/Company' component={Company} />
      <Route path='/ProductList' component={ProductList} />
      <Route exact path='/New_Product' component={New_Product} />
      {loggedIn ? <>
        <Route exact path='/All_P' component={All_P} />
        <Route exact path='/SalesMan' component={SalesMan} />
        <Route exact path='/Demo' component={Demo} />
        <Route exact path='/components/customer/CustomerDashboard' component={CustomerDashboard} />
        <Route exact path='/components/customer/OrderByComp_Customer' component={OrderByComp_Customer} />
        <Route exact path='/components/salesman/SalesmanDashboard' component={SalesmanDashboard} />
        <Route exact path='/components/company/CompanyDashboard' component={CompanyDashboard} />
        <Route exact path='/components/salesman/SmOrder' component={SmOrder} />
        <Route exact path='/components/salesman/OrderByComp' component={OrderByComp} />
        <Route exact path='/components/customer/Ledger' component={Ledger} />
        <Route exact path='/components/customer/PendingBills' component={PendingBills} />
        <Route exact path='/components/customer/BounceList' component={BounceList} />
        <Route exact path='/components/customer/BillTracking' component={BillTracking} />
        <Route exact path='/components/customer/OrderList' component={OrderList} />
        <Route exact path='/components/customer/DownLoadInvoice' component={DownloadInvoice} />
        <Route exact path='/OrderUpload/' component={OrderUpload} />
      </> : <>
        {(
          window.location.pathname !== "/" &&
          window.location.pathname !== "/Company" &&
          window.location.pathname !== "/ProductList" &&
          window.location.pathname !== "/New_Product" &&
          window.location.pathname !== "/Customer"

        ) && < Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited needs login."
            extra={<Button onClick={() => window.location.replace("/")} type="primary">Back Home</Button>}
          />
        }
      </>
      }
    </>
  )
}
export default RoutesComponents;