
import { useEffect, useState } from 'react';
import Header from './Header';
import Pfooter from './Pfooter';
import axios from 'axios';
import './App.css'
import { Table } from 'react-bootstrap';
import GlobalVariable from './GlobalVariable';
import ApiHandler from './ApiHandler';
import LoaderTri from './components/Loader/LoaderTri';


export default function New_Product() {

    const [newProduct, setNewProduct] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true)

        const cancelToken = ApiHandler.cancelToken;
        const source = cancelToken.source();
        ApiHandler.get({

            url: "/api/POPSReports/NewProducts/" + GlobalVariable.wcClient,
            cancelToken: source.token

        })
            .then(((result) => {

                console.log(result);
                setNewProduct(result)
            }))
            .catch(err => {
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                }

            })
            .finally(() => {
                setIsLoading(false)

            })
        return () => {
            source.cancel()
            setNewProduct([]);
        }
    }, [])

    return (

        <>
            <Header />
            <br />
            <div className='row m-0'>
                <div className='col-lg-12 text-center' >
                    <h5>New Products</h5>
                </div>
                <div className='col-lg-8 offset-lg-2'>

                    <div className="grids" style={{
                        width: "100%",
                        // marginLeft: "230px",
                        overflowX: "scroll"
                    }}>
                        <Table striped
                            style={{ border: "1px solid black", }} >
                            <thead style={{ backgroundColor: "#3ca0e7", color: "white" }}>
                                <tr style={{ border: "1px solid black", }}>
                                    <th style={{ minWidth: "200px", border: "1px solid black", }}>Product Name</th>
                                    <th style={{ border: "1px solid black", }}>Packing</th>
                                    <th style={{ border: "1px solid black", }}>Scheme</th>
                                    <th style={{ border: "1px solid black", }}>MRP</th>
                                </tr>
                            </thead>
                            <tbody >

                                {newProduct.map((elm, i) => {
                                    return <tr key={i}>
                                        <td >{elm.prodName}</td>
                                        <td > {elm.case1}</td>
                                        <td>{elm.scheme}</td>
                                        <td>{elm.mrp}</td>
                                    </tr>

                                })}

                            </tbody>
                        </Table>

                        {isLoading && <LoaderTri />}

                    </div>
                </div>
            </div>

            <Pfooter />
        </>
    )
}

