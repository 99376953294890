import React from 'react';
import { useState } from 'react';
import LoaderTri from '../../components/Loader/LoaderTri';
import { Image } from 'antd';
import sushilContactImage from '../../assets/images/w.jpg';

const ContactUs = () => {

    const [isLoading, setIsLoading] = useState(true);

    return (
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-md-7 text-center '>
                    <br />
                    {isLoading && <LoaderTri />}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3821.6339625281116!2d74.4567412!3d16.6951906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc11d9df35da90f%3A0x9d2d7fc00ab87022!2sSushil%20Pharma%20LLP!5e0!3m2!1sen!2sin!4v1689679756648!5m2!1sen!2sin"
                        width="90%" height="450"
                        style={{ border: 0 }}
                        allowFullScreen={false}
                        title='Sushil Pharma LLP Location'
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        onLoad={() => {
                            console.log("onLoad");
                            setIsLoading(false);
                        }}
                    ></iframe>


                    <br />
                    <br />
                    <br />
                </div>
                <div className='col-md-5'>
                    <br />
                    {/* <br />
                    <br /> */}

                    <div className=' ' style={{ width: "90%" }}>
                        <h4>Sushil Pharma LLP</h4>

                        <h6 >
                            Near, NIGHT COLLEGE, Jawaharnagar, Ichalkaranji, Maharashtra 416115       </h6>
                        <br />
                        <Image

                            src={sushilContactImage}
                            width={"100%"}
                            height={"auto"}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactUs