import img2 from '../Images/img2.png';
import R_Logo from '../Images/R_Logo.png';
import { Link, useHistory } from 'react-router-dom';
import Pfooter from '../Pfooter';
import '../App.css';
import { useState } from 'react';
import { toast } from 'react-toastify';
import GlobalVariable from '../GlobalVariable';
import ApiHandler from '../ApiHandler';
import { userActions } from '../store/user-slice';
import { useDispatch } from 'react-redux';


export default function Company() {
    const [data, setData] = useState({ username: "", password: "", });
    const dispatch = useDispatch();


    const changeHandler = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const history = useHistory();

    async function checkUser(e) {

        e.preventDefault();


        let item = {
            userId: data.username,
            password: data.password,
            wclientid: GlobalVariable.wcClient.toString(),

        };
        // let token="";


        ApiHandler.post({
            url: "/api/Com/Authenticate/AuthTokenCom/",
            data: item,

        }
        )
            .then(((result) => {
                console.log(result.data.token)
                if (result.data.isSuccess) {
                    dispatch(userActions.setLogin(true));

                    history.push("/components/company/CompanyDashboard")
                    localStorage.setItem('user-info', JSON.stringify(result.data))
                    // window.localStorage.setItem('loggedIn', true);

                    window.localStorage.setItem("app-type", "Com");
                }
                else {
                    throw result.data;
                }


            }))
            .catch(err => {
                toast.error("wrong username or password");
                console.error(err);
                if (err.response && err.response.status && err.response.status === 401) {
                    console.log(err.response);
                    //   RefreshTokenHandler();
                    //  ToastError("Token has expired Please refresh");
                }
            })





    }
    return (
      <>
        <div className="customer_header">
          <Link to="/">
            <img
              src={img2}
              height={30}
              width={30}
              id="S"
              alt=""
              style={{ marginTop: "-10px" }}
            />
          </Link>
          <h4
            style={{ marginTop: "40px", color: " #882205", fontSize: "27px" }}
          >
            KADLIMATTI PHARMACEUTICALS
          </h4>
          <img
            src={R_Logo}
            height={50}
            width={50}
            className="R"
            alt=""
            style={{ marginTop: "0px" }}
          />
        </div>

        <form onSubmit={checkUser}>
          <div className="row m-0">
            <div className="col-lg-6 offset-lg-3 p-2  mb-2">
              <div className="card mt-4 ">
                <h5
                  style={{
                    backgroundColor: "rgb(242, 241, 241)",
                    fontSize: "25px",
                    fontWeight: "300",
                    padding: "10px",
                  }}
                >
                  Company Login
                </h5>
                <div className="p-2">
                  <label
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      fontWeight: "650",
                    }}
                  >
                    Rep Id
                  </label>
                  <br />
                  <input
                    name="username"
                    className="form-control"
                    onChange={changeHandler}
                    value={data.username}
                    id="username"
                    type="text"
                    placeholder="Rep Id"
                  />
                  {/* </div>
                            <div> */}
                  <label
                    style={{
                      fontSize: "20px",
                      marginLeft: "10px",
                      fontWeight: "650",
                    }}
                  >
                    Password
                  </label>
                  <br />
                  <input
                    onChange={changeHandler}
                    className="form-control"
                    name="password"
                    value={data.password}
                    id="email"
                    type="password"
                    placeholder="Password "
                  />
                </div>
                <button
                  type="submit"
                  className="btns"
                  style={{ backgroundColor: "green", color: "white" }}
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
          {/* <div className='loginForm'></div> */}
        </form>
        <Pfooter />
      </>
    );
}