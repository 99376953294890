import React, { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { AsyncTypeahead, Hint, Menu, MenuItem } from 'react-bootstrap-typeahead';

import PropsType from "prop-types";

import './Types.css';

const Typeahead = forwardRef((propsFull, ref,) => {
    const filterBy = () => true;

    const { tabIndex, colWidth,
        isMobile,
        columns,
        labelKey,
        hasError,
        className,
        stock,
        showStock,
        width,
        noPadding,
        ...props } = propsFull;
    //  console.log(showStock)

    return (
        <AsyncTypeahead
            //style={{ overflow: 'hidden' }}
            {...props}
            className={props.className + " search"}
            ref={ref}
            clearButton
            renderInput={({ inputRef, referenceElementRef, ...inputPropsFirst }) => {
                // const { newSelectionPrefix, ...inputProps } = inputPropsFirst;
                // console.log({ inputPropsFirst });

                return (
                    <Hint

                        shouldSelect={(shouldSelect, e) => {
                            //         // Select the hint if the user hits 'enter' or ','
                            return e.keyCode === 13 || shouldSelect;
                        }}
                    >
                        <Form.Control
                            {...inputPropsFirst}
                            style={{ ...inputPropsFirst.style, padding: "14px", position: "inherit" }}
                            autoComplete="none"
                            tabIndex={tabIndex}
                            className="form-control form-control-sm"
                            ref={(element) => {
                                inputRef(element);
                                referenceElementRef(element);
                            }}
                        />
                    </Hint>
                );
            }}
            renderMenu={(results, menuPropsss) => {

                // console.log({ menuProps });

                if (!columns || columns.length === 0) {
                    return (
                        <Menu {...menuPropsss}  >
                            {results.map((result, index) => (
                                <MenuItem key={index} option={result} position={index}>
                                    {props.name ? result[props.name] : result.label}
                                </MenuItem>
                            ))}
                        </Menu>
                    );

                }

                // if (results.length === 0) {
                //     return <></>
                // }
                return (
                    <Menu
                        {...menuPropsss}

                        className={menuPropsss.className + " MultiColumn"}
                        style={{
                            ...menuPropsss.style,
                            height: "400px",
                            zIndex: 9999,
                            maxHeight: "1000px !important",
                            padding: 0,
                            backgroundColor: "#dcdc59"
                        }}
                    >
                        {/* {results.length > 0 ? */}
                        {/* ( */}
                        <>
                            {window.innerWidth > 425 ? (
                                <>
                                    <div
                                        className="row m-0"
                                        style={{
                                            backgroundColor: "#5BC0DE",

                                            // margin: 1,
                                            // paddingLeft: "18px "
                                        }}
                                    >
                                        {columns.map((data, i) => (
                                            <InputForWidth

                                                key={i}
                                                value={data.header}
                                                header={true}
                                                cols={data.cols}
                                            />
                                        ))}
                                    </div>
                                    {/* <div

                                            style={{
                                                // overflowY: "scroll",
                                                // overflowX: "hidden",
                                                height: "100%"
                                                // width: "1194px",
                                            }}
                                        > */}
                                    {results.map((result, index) => {
                                        //console.log({ columns, result });
                                        return (
                                            <MenuItem
                                                style={{
                                                    border: "1px solid black",
                                                    overflowX: "hidden !important",
                                                    width: "100%",

                                                }}
                                                className="menu border-bottom"
                                                option={result}
                                                key={index}
                                                position={index}
                                            >
                                                <div className="row ">
                                                    {columns.map((data, i) => (
                                                        <InputForWidth
                                                            key={i}
                                                            style={{
                                                                textWrap: "balance",
                                                                // backgroundColor: data.field === "stock" && parseFloat(result.stock) === 0 && "red"
                                                                backgroundColor: data.field === "stock" && parseFloat(result.stock) === 0 ? "red" : data.field === "stock" && parseFloat(result.stock) !== 0 && "green",
                                                                color: data.field === "stock" && "white",
                                                                height: "100%",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                // textAlign: data.field === "stock" && 'center',
                                                            }}
                                                            value={result[data.field]}
                                                            cols={data.cols}
                                                        />
                                                    ))}
                                                </div>
                                            </MenuItem>
                                        )
                                    })}
                                    {/* </div> */}
                                </>
                            ) : (
                                <>
                                    {results.map((result, index) => (
                                        <MenuItem

                                            className="border-bottom"
                                            option={result}
                                            key={index}
                                            position={index}
                                            style={{ fontSize: 15 }}
                                        >
                                            {
                                                columns.map((data, i) => (
                                                    <React.Fragment key={i}>
                                                        <div className={'row' + (i === 0 ? " border-bottom mb-2 " : "")}
                                                            style={{ width: "100%", paddingLeft: 5 }}>
                                                            <div className={'col-5'}
                                                                style={{ fontWeight: i === 0 ? 500 : 400 }}
                                                            >{data.header}</div>
                                                            <div className='col-7'
                                                                style={{
                                                                    backgroundColor: data.field === "stock" && parseFloat(result.stock) === 0 ? "red" : data.field === "stock" && parseFloat(result.stock) !== 0 && "green",
                                                                    color: data.field === "stock" && "white",
                                                                }}>
                                                                {result[data.field]}
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ))
                                            }
                                        </MenuItem>
                                    ))}
                                </>
                            )}
                        </>
                        {/* ) : (

                        <Empty /> */}
                        {/* ) */}
                        {/* } */}
                    </Menu>
                );
            }}
            filterBy={filterBy}
            minLength={2}
            labelKey={labelKey ? labelKey : "name"}
            inputProps={{
                autoComplete: "none",

                className:
                    "  " + (hasError ? "is-invalid" : "") + "  " + className
                        ? className
                        : "",
            }}
            multiple={false}
            autocomplete="none"
            useCache={false}

        />
    );
});


// https://reactjs.org/docs/typechecking-with-proptypes.html
Typeahead.propTypes = {
    name: PropsType.string,
    placeholder: PropsType.string,
    colWidth: PropsType.oneOfType([
        PropsType.string,
        PropsType.number
    ]),
    isMobile: PropsType.bool,
    tabIndex: PropsType.number,
    id: PropsType.string,
    columns: PropsType.array,
    isLoading: PropsType.bool,
    options: PropsType.array,
    selected: PropsType.array,
    onSearch: PropsType.func,
    onChange: PropsType.func,


}



export default Typeahead;



const InputForWidth = (props) => (<div
    style={{
        // minWidth: "167px",

    }}
    className={
        `col-${props.cols ? props.cols : 0} border-right  p-0 pr-1 pl-2 ` +
        (props.header ? " border-bottom  " : " ")
    }
>
    <div
        className={`rowT ${props.header ? "text- center" : ""}`}
        // className={" bg-transparent border-0   "}
        style={{
            ...props.style,
            fontSize: "14px",
            fontWeight: props.header ? "500" : "400" && "bold",
            padding: '18px 0px 18px 0px',
            paddingLeft: "8px",
            // backgroundColor: data.field === "companyName" ? "black" : "blue"
        }}
    >
        {props.value ? props.value : ""}
    </div>
</div>);
