// export const env = { ...process.env, ...window['env'] };

const DEFAULT_DEVELOPMENt_ENVS = {
  ...process.env
};

const generateEnv = () => {
  if (process.env.NODE_ENV !== "production") {
    return DEFAULT_DEVELOPMENt_ENVS;
  }
  return { ...process.env, ...window.env };
};

export const env = generateEnv();
