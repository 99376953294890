// import img2 from "./img2.png";
import img2 from "../../Images/img2.png";
import R_Logo from "./R_Logo.png";
import { TbCaretDownFilled } from "react-icons/tb";
// import R_Logo from './R_Logo.png'
import { Link } from "react-router-dom";
import "./Customer.css";
import { useState, useRef, useEffect, useMemo } from "react";

export default function HeaderCustomer() {
  const [visible, setVisible] = useState(false);
  const [showUserDetail, setShowUserDetail] = useState(false);

  //const history = useHistory();
  function logout() {
    window.localStorage.removeItem("user-info");
    window.localStorage.removeItem("app-type");
    //history.push('/Customer');
    window.location.href = "/Customer";
  }
  const refOne = useRef(null);

  useEffect(() => {
    // document.addEventListener("click", hideOnClickOutside, true)
    // return () => {
    //     document.removeEventListener("click", hideOnClickOutside, true)
    // }
  }, []);

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      console.log("Outside");
      setVisible(false);
    }
  };

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = (event) => {
    const { scrollLeft } = event.target;
    setScrollPosition(-scrollLeft);
  };

  // useEffect(() => {

  //     console.log({ visible });
  //     return () => {

  //     }
  // }, [visible])
  const userInfo = useMemo(
    () =>
      localStorage.getItem("user-info")
        ? JSON.parse(localStorage.getItem("user-info"))
        : null,
    []
  );
  return (
    <>
      <div className=" mainLogin">
        <div className="product_header">
          <Link to="/">
            <img src={img2} height={78} alt="" style={{ marginTop: "0px" }} />
          </Link>
          <h4
            style={{ marginTop: "40px", color: " #882205", fontSize: "27px" }}
          >
            {" "}
            KADLIMATTI PHARMACEUTICALS
          </h4>
          <img src={R_Logo} height={100} width={100} className="R" alt="" />
        </div>
      </div>

      {/* <div className="product_header">
                <Link to='/'><img src={img2} height={100} width={100} id="S" alt="" style={{ marginTop: "5px" }} /></Link>
                <img src={R_Logo} height={100} width={100} className="R" alt="" />
            </div> */}
      <div class="navbar" style={{ backgroundColor: "#F5F5F5" }}>
        <div
          className="col-md-12 d-flex justify-content-end  align-items-end"
          style={{ marginRight: "10px " }}
        >
          <div>
            {userInfo ? "Welcome: " + userInfo.name : ""}
            {/* <br /> */}
            <div className="text-end">
              {userInfo ? "  " + userInfo.place : ""}
            </div>
          </div>
        </div>
        <div className="Cust-Order-list" onScroll={handleScroll}>
          <Link
            to="/components/customer/CustomerDashboard"
            style={{ textDecoration: "none" }}
          >
            <li
              style={{
                display: "inline-block",
                outline: "none",
                cursor: "pointer",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "600",
                borderRadius: "8px",
                padding: "14px 24px",
                border: "none",
                transition:
                  "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s",
                background: "#5cb85c",
                color: "#fff",
                marginRight: "10px ",
                marginLeft: "10px",
              }}
            >
              Order
            </li>
          </Link>
          {/* <Link to="/OrderUpload/" style={{ textDecoration: "none" }}>
          <li className=" ">Order Upload</li>
        </Link> */}
          <Link
            to="/components/customer/OrderByComp_Customer"
            style={{ textDecoration: "none" }}
          >
            <li
              style={{
                display: "inline-block",
                outline: "none",
                cursor: "pointer",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "600",
                borderRadius: "8px",
                padding: "14px 24px",
                border: "none",
                transition:
                  "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s",
                background: "#0275d8",
                color: "#fff",
                marginRight: "10px ",
              }}
            >
              Order by Comp{" "}
            </li>
          </Link>
          <span
            onMouseLeave={() => {
              // console.log("Leaved");
              setVisible(false);
              // setShowUserDetail(true)
            }}
          >
            <li
              style={{
                display: "inline-block",
                outline: "none",
                cursor: "pointer",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "600",
                borderRadius: "8px",
                padding: "14px 24px",
                border: "none",
                transition:
                  "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s",
                background: "#d9534f",
                color: "#fff",
                marginRight: "10px ",
              }}
              onMouseEnter={() => {
                // console.log("Entered");
                setVisible(true);
                setShowUserDetail(false);
              }}
              to=" "
              onClick={() => {
                // console.log({ showUserDetail, visible });
                setShowUserDetail(!showUserDetail);
                setVisible(!visible);
              }}
              // style={{
              //   color: "black",
              //   marginTop: "0px",
              //   padding: "10px",
              // }}
            >
              Reports
              <TbCaretDownFilled />
            </li>

            {visible && (
              <div
                className="Dreport_list"
                onMouseLeave={() => setVisible(false)}
                ref={refOne}
                style={{
                  transform: `translateX(${scrollPosition}px)`,
                }}
              >
                <Link
                  to="/components/customer/Ledger"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p
                    className="list_reports"
                    style={{ padding: "5px 0px 5px 10px" }}
                  >
                    View ledger
                  </p>
                </Link>
                <Link
                  to="/components/customer/PendingBills"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  <p
                    className="list_reports"
                    style={{ padding: "5px 0px 5px 10px" }}
                  >
                    Pending bills{" "}
                  </p>
                </Link>
                <Link
                  to="/components/customer/DownLoadInvoice"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <p
                    className="list_reports"
                    style={{ padding: "5px 0px 5px 10px" }}
                  >
                    {" "}
                    Download <br />
                    Invoice
                  </p>
                </Link>
                <Link
                  to="/components/customer/OrderList"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  <p
                    className="list_reports"
                    style={{ padding: "5px 0px 5px 10px" }}
                  >
                    Order List
                  </p>
                </Link>
                <Link
                  to="/components/customer/BounceList"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  <p
                    className="list_reports"
                    style={{ padding: "5px 0px 5px 10px" }}
                  >
                    Bounce List{" "}
                  </p>
                </Link>
                <Link
                  to="/components/customer/BillTracking"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  {" "}
                  <p
                    className="list_reports"
                    style={{ padding: "5px 0px 5px 10px" }}
                  >
                    Bill Tracking
                  </p>
                </Link>
              </div>
            )}
          </span>

          <span
            onMouseLeave={() => {
              setShowUserDetail(false);
            }}
          >
            <div
              className="user"
              // className="custom-button"
              style={{
                textDecoration: "none",
                listStyle: "none",
              }}
              onMouseEnter={() => {
                console.log("User over");
                setShowUserDetail(true);
                setVisible(false);
              }}
              onClick={() => {
                setShowUserDetail(!showUserDetail);
              }}
            >
              <li
                style={{
                  display: "inline-block",
                  outline: "none",
                  cursor: "pointer",
                  fontSize: "16px",
                  lineHeight: "20px",
                  fontWeight: "600",
                  borderRadius: "8px",
                  padding: "14px 24px",
                  border: "none",
                  transition:
                    "box-shadow 0.2s ease 0s, -ms-transform 0.1s ease 0s, -webkit-transform 0.1s ease 0s, transform 0.1s ease 0s",
                  background: "#f0ad4e",
                  color: "#fff",
                  marginRight: "10px ",
                }}
              >
                User
                <TbCaretDownFilled />
              </li>
            </div>
            {showUserDetail && (
              <li
                style={{
                  transform: `translateX(${scrollPosition}px)`,
                  backgroundColor: "#FDE68A",
                }}
                onClick={logout}
                className="logOut_customer"
                onMouseLeave={() => setShowUserDetail(false)}
                ref={refOne}
              >
                Log Out
              </li>
            )}
          </span>
        </div>
      </div>
    </>
  );
}
