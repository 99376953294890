import SalesManHeader from "./SalesManHeader";
import './Salesman.css';
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import axios from "axios";
import GlobalVariable from "../../GlobalVariable";
import ApiHandler from "../../ApiHandler";
export default function OrderByComp() {

    const [data, setData] = useState([]);
    const [productData, setProductData] = useState([]);
    // const [isProduct, setIsProduct] = useState(false);
    const [comCode, setComCode] = useState('');
    const [value, setValue] = useState('--Select--');
    const [input, setInput] = useState('');
    const [showList, setShowList] = useState(false)
    let pickuporder = "PickUp Order";
    let deliveryOrder = "Delivery Order";

    useEffect(() => {

        ApiHandler.get({
            url: "api/POPSReports/AllCompanies/" + GlobalVariable.wcClient,
        })
            .then((res) => {
                setData(res);
            })
    }, [])





    useEffect(() => {

        console.log(comCode);
        if (comCode) {
            ApiHandler.post({
                url: "api/POPSReports/productbycompanycode/",
                data:
                {
                    wClientid: GlobalVariable.wcClient + "",
                    search: comCode + ""
                }
            }).then(res => {
                console.log(res.data);
                setProductData([...res.data])
            }).catch(err => {
                console.error(err);
            })
        }
    }, [comCode]
    )

    return (
      <>
        <SalesManHeader />
        <div className="POD">
          <h1
            style={{
              textAlign: "center",
              paddingBottom: "20px",
              fontFamily: "Serif",
              fontWeight: "600",
              fontSize: "28px",
            }}
          >
            Place order Company wise
          </h1>
        </div>

        <div className="dropdownss">
          <span className="dropdwnsNamee">
            {value}
            {input}
          </span>
          <div className="dropdown-contentt">
            {data.map((elm, i) => {
              return (
                <option
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setComCode(elm.compCode);
                    setShowList(true);
                    console.log(elm.compCode);
                  }}
                >
                  <option
                    key={elm.compCode}
                    // onClick={() => {
                    //     setComCode(elm.compCode);
                    //     setIsProduct(true);
                    //     console.log(elm.compCode);

                    // }}
                  >
                    {elm.name}{" "}
                  </option>
                </option>
              );
            })}
          </div>
        </div>

        <input
          type="text"
          className="ProductSearch"
          placeholder="Enter Product Name..."
        />

        <div className="grids">
          <Table striped bordered variant=" ">
            <thead>
              <tr
                style={{
                  backgroundColor: "#00aff0",
                  color: "white",
                  border: "1px solid black",
                }}
              >
                <th>#</th>
                <th>Product Name</th>
                <th style={{ marginRight: "-50px" }}>Pack</th>
                <th style={{ marginRight: "-50px" }}>Qty</th>
              </tr>
            </thead>

            {showList ? (
              <tbody>
                {productData.map((elm, i) => {
                  return (
                    <tr key={i} style={{ border: "1px solid black" }}>
                      <td style={{}}>{i + 1}</td>
                      <td>
                        <span className="PrdtName">{elm.prodName}</span>
                        <br />
                        <p>
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "rgb(106, 106, 106)",
                            }}
                          >
                            {" "}
                            Stock:
                          </span>
                          {elm.stock}
                        </p>
                        <p
                          style={{
                            marginLeft: "80px",
                            marginTop: "-40px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "rgb(106, 106, 106)",
                            }}
                          >
                            {" "}
                            MRP:{" "}
                          </span>
                          {elm.mrp}
                        </p>
                        <p
                          style={{
                            marginBottom: "-5px",
                            marginTop: "-10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "rgb(106, 106, 106)",
                            }}
                          >
                            {" "}
                            Scheme:{" "}
                          </span>
                          {elm.scheme}
                        </p>
                      </td>
                      <td style={{ marginRight: "-50px" }}> {elm.case1}</td>

                      <td style={{ marginRight: "-50px" }}>
                        <input type="number" className="InputNumber" />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </Table>
        </div>

        <div className="DropC">
          <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>
            Priority
          </h5>
          <select>
            <option>Normal</option>
            <option>Urgent</option>
          </select>
        </div>
        <div className="remarks">
          <h5 style={{ fontFamily: "Futara", color: "rgb(89, 91, 91)" }}>
            Remarks
          </h5>

          <div className="dropdowns">
            <span className="dropdwnsName">
              {value}
              {input}
            </span>
            <div className="dropdown-content">
              <li
                name="PickUpOrder"
                onClick={() => setValue(pickuporder)}
                value="PickupOrder"
                style={{ color: "rgb(89, 91, 91)" }}
              >
                Pickup Order
              </li>
              <li
                onClick={() => setValue(deliveryOrder)}
                value="Delivery Order"
                style={{ color: "rgb(89, 91, 91)" }}
              >
                Delivery Order
              </li>
              <li
                onClick={() => setInput()}
                value="Remarks"
                style={{ fontWeight: "bold" }}
              >
                Remarks
                <textarea
                  name="Text1"
                  cols="68"
                  rows="2"
                  onChange={(e) => setValue(e.target.value)}
                ></textarea>
              </li>
            </div>
          </div>

          <h5
            style={{
              marginTop: "50px",
              marginBottom: "50px",
              fontFamily: "Futara",
              color: "rgb(89, 91, 91)",
            }}
          >
            Approx Order Value : Rs.
            {}
          </h5>
        </div>

        <button type="submit" className="SubmitButton">
          Submit
        </button>
      </>
    );
}